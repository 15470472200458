import Api from '@api';

import {PAGER, CONSIGNMENT_STATUS, YN} from '@constant';

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        "key": "CONSIGNMENT",
        "label": "托运",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入托运",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SITE_ID",
        "label": "站点",
        "type": "multiselect",
        "placeholder": "请选择站点",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "valueType": 'SINGLE_VALUE',
        "options": async () => {
          let {status, data: {list}, message} = await Api.setSelectSiteList({
            "SITE": {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item => {
              return {
                key: item.SITE_ID,
                label: item.SITE_ID
              }
            })
          }
        },
      },
      {
        "key": "STATUS",
        "label": "状态 ",
        "type": "multiselect",
        "placeholder": "请选择状态 ",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": CONSIGNMENT_STATUS,
        "formatter": ({cellValue, row, column}) => {
          let res = CONSIGNMENT_STATUS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "TRAILER_ID",
        "label": "拖车",
        "type": "magnifier",
        "tableName": 'CONSIGNMENT',
        "placeholder": "请输入拖车",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TOTAL_CUSTOMERS",
        "label": "客户数",
        "type": "numrange",
        "placeholder": "请选择客户数",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PICK_COMPLETE_DSTAMP",
        "label": "拣货完成日期",
        "type": "date",
        "placeholder": "请选择拣货完成日期",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TRAILER_TYPE",
        "label": "拖车类型",
        "type": "numrange",
        "placeholder": "请选择拖车类型",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TOTAL_ORDERS",
        "label": "订单数",
        "type": "numrange",
        "placeholder": "请选择订单数",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PICK_COMPLETE_TIME",
        "label": "拣货完成时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择拣货完成时间",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "SEAL_ID",
        "label": "封条号码",
        "type": "magnifier",
        "tableName": 'CONSIGNMENT',
        "placeholder": "请输入封条号码",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TOTAL_PRE_ADVICES",
        "label": "预通知数",
        "type": "numrange",
        "placeholder": "请选择预通知数",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LOAD_COMPLETE_DSTAMP",
        "label": "装载完成日期",
        "type": "date",
        "placeholder": "请选择装载完成日期",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TEMPERATURE",
        "label": "温度 ",
        "type": "numrange",
        "placeholder": "请选择温度 ",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TOTAL_UPIS",
        "label": "UPI数量",
        "type": "numrange",
        "placeholder": "请选择UPI数量",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LOAD_COMPLETE_TIME",
        "label": "装载完成时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择装载完成时间",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "SHIPMENT_DSTAMP",
        "label": "发运日期",
        "type": "date",
        "placeholder": "请选择发运日期",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SHIPMENT_TIME",
        "label": "发运时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择发运时间",
        "tabGroup": "mqs_consignment_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "EXPECTED_VALUE",
        "label": "预计价值",
        "type": "numrange",
        "placeholder": "请选择预计价值",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "VALUE",
        "label": "价值",
        "type": "numrange",
        "placeholder": "请选择价值",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "YARD_CONTAINER_ID",
        "label": "集装箱号",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入集装箱号",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "EXPECTED_VOLUME",
        "label": "预计体积",
        "type": "numrange",
        "placeholder": "请选择预计体积",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "VOLUME",
        "label": "体积",
        "type": "numrange",
        "placeholder": "请选择体积",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CARRIER_ID",
        "label": "承运商",
        "type": "autoComplete",
        "placeholder": "请输入承运商",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "EXPECTED_WEIGHT",
        "label": "预计重量",
        "type": "numrange",
        "placeholder": "请选择预计重量",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "WEIGHT",
        "label": "重量",
        "type": "numrange",
        "placeholder": "请选择重量",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "EXPECTED_LOAD_METRES",
        "label": "预计装载米数",
        "type": "numrange",
        "placeholder": "请选择预计装载米数",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LOAD_METRES",
        "label": "装载米数",
        "type": "numrange",
        "placeholder": "请选择装载米数",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SERVICE_LEVEL",
        "label": "服务级别 ",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入服务级别 ",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PROFORMA_INVOICE_NUM",
        "label": "发票号码",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入发票号码",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "YARD_CONTAINER_TYPE",
        "label": "集装箱类型",
        "type": "autoComplete", tableName: "CONSIGNMENT",
        "placeholder": "请输入集装箱类型",
        "tabGroup": "mqs_consignment_tab1",
        "tabGroupName": "仓库",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATED_BY",
        "label": "创建人 ",
        "type": "magnifier",
        "tableName": 'CONSIGNMENT',
        "placeholder": "请输入创建人 ",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATION_DATE",
        "label": "创建日期 ",
        "type": "date",
        "placeholder": "请选择创建日期 ",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATION_TIME",
        "label": "创建时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择创建时间",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATED_BY",
        "label": "最后更新人",
        "type": "magnifier",
        "tableName": 'CONSIGNMENT',
        "placeholder": "请输入最后更新人",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATE_DATE",
        "label": "最后更新日期",
        "type": "date",
        "placeholder": "请选择最后更新日期",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATE_TIME",
        "label": "最后更新时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择最后更新时间",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "UPLOADED_WS2PC_ID",
        "label": "Web 服务负载 2PC ID",
        "type": "numrange",
        "placeholder": "请选择Web 服务负载 2PC ID",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [],
        "checked": false
      },
      {
        "key": "UPLOADED",
        "label": "上传",
        "type": "multiselect",
        "placeholder": "请选择上传",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "UPLOADED_FILENAME",
        "label": "上传文件名",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入上传文件名",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPLOADED_DSTAMP",
        "label": "上传日期",
        "type": "date",
        "placeholder": "请选择上传日期",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPLOADED_TIME",
        "label": "上传时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择上传时间",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "CONSIGNMENT_CLOSED",
        "label": "是否关闭",
        "type": "multiselect",
        "placeholder": "请选择是否关闭",
        "tabGroup": "mqs_consignment_tab2",
        "tabGroupName": "客户",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "USER_DEF_TYPE_1",
        "label": "用户自定义类型 1 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 1 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_2",
        "label": "用户自定义类型 2 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 2 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_3",
        "label": "用户自定义类型 3 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 3 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_4",
        "label": "用户自定义类型 4 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 4 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_5",
        "label": "用户自定义类型 5 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 5 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_6",
        "label": "用户自定义类型 6 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 6 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_7",
        "label": "用户自定义类型 7 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 7 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_8",
        "label": "用户自定义类型 8 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 8 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_CHK_1",
        "label": "用户自定义的检查1 ",
        "type": "select",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查1 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_2",
        "label": "用户自定义的检查2 ",
        "type": "select",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查2 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_3",
        "label": "用户自定义的检查3 ",
        "type": "select",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查3 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_4",
        "label": "用户自定义的检查4 ",
        "type": "select",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查4 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_DATE_1",
        "label": "用户自定义日期 1 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 1 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_1",
        "label": "用户自定义时间 1 ",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 1 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_2",
        "label": "用户自定义日期 2 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 2 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_2",
        "label": "用户自定义时间 2 ",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 2",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_3",
        "label": "用户自定义日期 3 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 3 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_3",
        "label": "用户自定义时间 3",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 3",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_4",
        "label": "用户自定义日期 4 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 4 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_4",
        "label": "用户自定义时间 4",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 4",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_1",
        "label": "用户自定义号码 1 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 1 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_2",
        "label": "用户自定义号码 2",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 2",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_3",
        "label": "用户自定义号码 3 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 3 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_4",
        "label": "用户自定义号码 4 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 4 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NOTE_1",
        "label": "用户自定义注释 1 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义注释 1 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NOTE_2",
        "label": "用户自定义注释 2 ",
        "type": "magnifier",
        "tableName":'UPI_HEADER',
        "placeholder": "请输入用户自定义注释 2 ",
        "tabGroup": "mqs_cons_user_def_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      },
      {
        "key": "CREATED_BY_TM",
        "label": "",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      },
      {
        "key": "ORDER_GROUPING_ID",
        "label": "旧订单组ID",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入旧订单组ID",
        "showBlock": [],
        "checked": false
      },
      {
        "key": "TM_MOVE_PLAN_ID",
        "label": "",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      },
      {
        "key": "UPLOADED_LABOR",
        "label": "",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      },
      {
        "key": "UPLOADED_TM",
        "label": "",
        "type": "autoComplete",
        tableName: "CONSIGNMENT",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      },
    ]
  }
}